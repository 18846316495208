const defaultNostrRelays = [
  {
  "id": "wss://140.f7z.io",
    "checked": false
  },
  {
  "id": "wss://relay.nostr.bg",
    "checked": false
  },
  {
  "id": "wss://relay.damus.io",
    "checked": false
  },
  {
  "id": "wss://nostr.wine",
    "checked": false
  },
  {
  "id": "wss://nos.lol",
    "checked": false
  },
  {
  "id": "wss://nostr.mom",
    "checked": false
  },
  {
  "id": "wss://atlas.nostr.land",
    "checked": false
  },
  {
  "id": "wss://relay.snort.social",
    "checked": false
  },
  {
  "id": "wss://offchain.pub",
    "checked": false
  },
  {
  "id": "wss://nostr-pub.wellorder.net",
    "checked": false
  },
  {
    "id": "ws://0.0.0.0:8080",
      "checked": true
    }
  
]
export default defaultNostrRelays