const defaultTrainLabels= [
  { "id": "AI", "checked": true },
  { "id": "maritime", "checked": false },
  { "id": "work", "checked": false },
  { "id": "science", "checked": true },
  { "id": "bitcoin", "checked": true },
  { "id": "local", "checked": true },
  { "id": "business", "checked": true },
  { "id": "jobs", "checked": false },
  { "id": "world", "checked": true },
  { "id": "politics", "checked": true },
  { "id": "technology", "checked": false },
  { "id": "variety", "checked": false },
  { "id": "medicine", "checked": false }
]
export default defaultTrainLabels
